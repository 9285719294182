<template>
  <div
    class="result-container"
    :style="{
      backgroundImage:
        'url(' + (resultStatus == '01' ? img1 : resultStatus == '03' ? img2 : img3) + ')',
    }"
  >
    <div class="result-main">
      <div class="p-t44 rem46 lh31">
        <span v-if="resultStatus === '03'">处理中</span>
        <span v-if="resultStatus === '01'">付款成功</span>
        <span v-if="resultStatus === '02'" class="c-FE4D4DFF">付款失败</span>
      </div>
      <div class="rem30 lh31 c-8E929B">
        <span v-if="resultStatus === '03'">请等待，您的付款正在处理中</span>
        <span v-if="resultStatus === '01'">恭喜您，本周账单付款成功</span>
        <span v-if="resultStatus === '02'">抱歉，本周账单付款失败</span>
      </div>
      <div class="result-body">
        <div class="rem30 lh21 mb7 f500">温馨提示</div>
        <div class="rem30 lh28 c-303133">
          <div v-if="resultStatus === '03'" class="flex">
            <span class="icon-qq"></span> 请稍后再查看，请勿重复提交付款申请
          </div>

          <div v-if="resultStatus === '01'">
            <div class="flex">
              <span class="icon-qq"></span>您本次已成功付款<span class="c-4D7BFE"
                >￥{{ amount }}</span
              >
              元
            </div>
            <div class="flex"><span class="icon-qq"></span>养成良好的付款习惯，有助于提高额度</div>
          </div>
          <div v-if="resultStatus === '02'" class="flex">
            <span class="icon-qq"></span>付款失败，请稍后再试
          </div>
        </div>
      </div>
      <div class="result-btn m5">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          v-if="resultStatus === '02'"
          @click="tryAgain"
        >
          <span class="rem34">返回重试</span>
        </van-button>
        <van-button round block type="info" v-else native-type="submit" @click="backPayList">
          <span class="rem34">返回账单页</span>
        </van-button>
        <van-button round block plain color="#4D7BFE" class="btn" @click="backHome">
          <span class="rem34">返回首页</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button } from 'vant'

Vue.use(Button)
export default {
  data() {
    return {
      resultStatus: null,
      amount: null,
      img1: require('../../assets/images/payment/result1.png'),
      img2: require('../../assets/images/payment/result2.png'),
      img3: require('../../assets/images/payment/result3.png'),
    }
  },
  created() {
    this.resultStatus = this.$route.query.resultStatus
    this.amount = this.$route.query.amount
  },
  methods: {
    // 返回重试
    tryAgain() {
      window.history.go(-1)
    },
    // 返回账单页
    backPayList() {
      this.$router.push({
        path: '/repaymentList',
        query: { type: 'UnsetlLoan' },
      })
    },

    // 返回首页
    backHome() {
      this.$router.push({ path: this.$store.state.common.defaultIndex })
    },
  },
}
</script>

<style lang="less" scoped>
.result-container {
  padding: 0 0.42rem /* 21/50 */;
  background-size: 100% 8.4rem /* 420/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .result-body {
    background-color: #fff;
    margin-top: 0.72rem /* 36/50 */;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 0.38rem /* 19/50 */ 0.3rem /* 15/50 */;
    border-radius: 4px;
    .icon-qq {
      margin-top: 0.18rem /* 9/50 */;
      display: inline-block;
      width: 0.2rem /* 10/50 */;
      height: 0.2rem /* 10/50 */;
      border-radius: 50%;
      border: 0.04rem /* 2/50 */ solid #4d7bfe;
      box-sizing: border-box;
      margin-right: 0.1rem /* 5/50 */;
    }
  }

  .result-btn {
    margin-top: 0.82rem /* 41/50 */;
  }
  .btn {
    margin-top: 0.48rem /* 24/50 */;
  }
}
</style>
